<div class="row itemlist">
  <div class="col-3">
    <div class="subtitle">{{ labelTranslation.companyName }}</div>
  </div>
  <div class="col-3">
    <div class="subtitle">{{ labelTranslation.location }}</div>
  </div>
  <div class="col-3">
    <div class="subtitle">{{ labelTranslation.pointOfContact }}</div>
  </div>
</div>
<div class="max-height">
  <div class="row-item" *ngFor="let subsidiarySupplier of subsidiarySuppliers">
    <tsm-parent-subsidiary-supplier-item
      [model]="subsidiarySupplier"
      [isSupplier]="isSupplier"
      [isSubsidiaryUser]="isSubsidiaryUser"
      [parentSupplierTsmId]="parentSupplierTsmId"
      [subSupplierMode]="subSupplierMode"
      [displayButtons]="displayButtons"
    >
    </tsm-parent-subsidiary-supplier-item>
  </div>
</div>
