<!-- <div *ngIf="errorState" class="ms-3 pb-3 search-error" style="padding-left: 1.75rem !important;margin-left: 3rem !important;">
    There seems to be an issue with your search. Please try again.
</div> -->
<!-- <div class="search-message" style="margin-left:5rem">Search for Suppliers in the True Supplier Marketplace</div> -->
<div class="search-header" *ngIf="searchIf">
    <h1 class="search-header">Let's find a supplier!</h1>
</div>
<div class="search-header-title" *ngIf="searchIf">
    Supplier Duplicate Check: Find existing supplier first!<img class="modal-icon" src="assets/icons/info-blue.svg"
        style="margin-top: -3px;margin-left: 5px;transform:rotate(180deg);" alt="icon-info" />
</div>
<div (click)="backPage()" *ngIf="!searchIf" class="ms-5"
    style="width: 60px;line-height:50px;font-size: 14px;font-weight:bold;font-family: Graphik;cursor: pointer;color: #4e4e87;">
    < Back </div>
        <!-- <div style="font-size: 24px;margin-left: 5rem;line-height: 66px;" *ngIf="!searchIf">
    49 search results for <span style="font-weight: bold;">{{DUNSnumber}}</span>
    
</div> -->
        <!-- <div class="search-and-catagory mx-5 px-1 row justify-content-between" > -->
        <div>
            <div class="search-content">
                <div class="search-container mx-4 ">
                    <div class="search-textbox d-flex rounded-pill bg-white ps-3 pe-1 py-1 search-error-textbox search-button"
                        [ngClass]="{ 'search-error-textbox': errorState }">
                        <input type="text" id="searchInput" class="pb-1 flex-grow-1" size="30" tabindex="0"
                            placeholder="Search by Supplier ID, International Company Name, SAP ID, BRN or DUNS Number" [(ngModel)]="searchCriteria.singleSearch"
                            (click)="clearError()" (keydown)="searchKeyDown($event)" aria-label="input-search"
                            (input)="checkSingleSearchLength($event)" maxlength="5000"/>
                        <div *ngIf="checkSingleSearch" id="SingleSearchErrMsg"
                            style="color: red;margin-top: 45px;
                white-space: nowrap;position: absolute; font-family:Graphik-Medium, Arial, Helvetica, sans-serif; font-size: 14px; overflow: auto;">Please enter more than
                            3 letters.</div>
                        <div class="sh-btn flex-row-reverse search-btn">
                            <sh-button tabindex="0" [props]="advSearchSearchButton"> </sh-button>
                        </div>
                    </div>
                </div>
                <div class="advanced-search-button advanced-search-supplier search-adv  align-items-center rounded-pill px-3 pt-1 pb-2 search-button"
                    style="margin-top: 10px;">
                    <strong class="advanced-search-btn search-btn cursor-pointer" style="color:rgb(78, 78, 135)"
                        (click)="toggleAdvancedSearch()" tabindex="0" (keydown.enter)="toggleAdvancedSearch()">
                        Advanced search
                        <svg width="1em" height="1em" viewBox="4 4 16 16" class="bi bi-chevron-down"
                            [ngClass]="{ rotate: isAdvanceSearchOpen }" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-label = "click on advance search dropdown button to view the advance search dropdown content">
                            <path fill-rule="evenodd" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                        </svg>
                    </strong>
                </div>
            </div>
        </div>
        <!--<div class="mx-4">-->
        <!--    <div-->
        <!--        *ngIf="!isAdvanceSearchOpen && categoryFilters"-->
        <!--        class="status-filter d-flex flex-row me-auto mb-3 ms-4 ps-1 mx-5"-->
        <!--    >-->
        <!--        <div-->
        <!--            class="tab cursor-pointer rounded-pill me-3"-->
        <!--            [ngClass]="{-->
        <!--                active: item.filter == searchCriteria.categoryFilter-->
        <!--            }"-->
        <!--            *ngFor="let item of categoryFilters"-->
        <!--            (click)="changeCategory(item.filter)"-->
        <!--        >-->
        <!--            {{ item.filter }} ({{ item.count | thousandSeparator }})-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</div>-->
        <div class="advanced-search advanced-search-content mx-5" *ngIf="isAdvanceSearchOpen"
            style="border-radius: 16px;margin-top: 10px;">
            <div class="search-panel bg-white shadow-sm mb-3 mx-0">
                <div class="filters-area row g-3 pb-3">
                    <div class="col-3">
                        <label for="tsmID" class="form-label" >TSM ID</label>
                        <input type="text" tabindex="2" class="form-control supplierName " [(ngModel)]="searchCriteria.tsmId"
                            placeholder="TSM ID" (input)="check()" id="tsmID" />
                        <!-- <div *ngIf="checkSapId" id="sapIdErrMsg" style="color: red;">Please enter</div> -->
                    </div>
                    <div class="col-3">
                        <label for="supplierName" class="form-label" id="internationalCompanyName"
                            [ngClass]="checkInternational ? 'red' : 'black'">International Company Name</label>
                        <input type="text" tabindex="3" class="form-control supplierName" [(ngModel)]="searchCriteria.International"
                            placeholder="International Company Name" (input)="checkcompanyName()" />
                        <div *ngIf="checkInternational" id="internationalErrMsg" style="color: red;">The text entered
                            exceeds the maximum length of 255.</div>
                    </div>
                    <div class="col-3">
                        <label for="category" class="form-label">Category</label>
                        <sh-multiple-dropdown tabindex="4" [dataSource]="category" [selectedKeys]="searchCriteria.category"
                            [placeholder]="'Category'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'category')"
                            id="category"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="supplierName" class="form-label" id="dunsNumber"
                            [ngClass]="checkNumber ? 'red' : 'black'">D-U-N-S Number</label>
                        <input type="text" tabindex="5" class="form-control supplierName" [(ngModel)]="searchCriteria.duns"
                            placeholder="D-U-N-S Number" (input)="check()" />
                        <div *ngIf="checkNumber" id="dunsNumberErrMsg" style="color: red;">Please enter a 9 digits
                            number.</div>
                    </div>
                    <div class="col-3">
                        <label for="countryLocationTwo" class="form-label">Company Location</label>
                        <sh-multiple-dropdown tabindex="6" [dataSource]="tempLocation"
                            [selectedKeys]="searchCriteria.countryLocationTwo" [placeholder]="'Company Location'"
                            [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'countryLocationTwo')"
                            id="countryLocationTwo"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="countryServedTwo" class="form-label">Country of Delivery</label>
                        <sh-multiple-dropdown tabindex="7" [dataSource]="activeCountryData"
                            [selectedKeys]="searchCriteria.countryServedTwo" [placeholder]="'Country of Delivery'"
                            [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'countryServedTwo')"
                            id="countryServedTwo"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="companyCode" class="form-label">Company Code</label>
                        <div class="error" *ngIf="isDisabled">Gets enabled based on Country of Delivery</div>
                        <sh-multiple-dropdown tabindex="8" [readonly]="true" [disabled]="isDisabled"
                            [dataSource]="tempcompanyCodeData" [selectedKeys]="searchCriteria.companyCode"
                            [placeholder]="'Company Code'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'companyCode')" id="companyCode"
                            [ngClass]="{ isDisable : (isDisabled === true) ? true : false}">
                        </sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="taxId" class="form-label taxId" [ngClass]="checkTaxId ? 'red' : 'black'">Business
                            Registration Number
                            <img class="info-icon-image" src="assets/icons/info-blue.svg" alt="icon-info">
                            <span class="info-icon">
                                <span class="tooltiptext">This field can be used to search based on BRN number, VAT ID
                                    and other Tax ID types.<br>
                                    Social Security Number (SSN) cannot be used to search suppliers.</span>
                            </span>
                        </label>
                        <input type="text" tabindex="9" id="taxId" class="form-control supplierName taxId"
                            [(ngModel)]="searchCriteria.taxId" placeholder="TAX ID" (input)="check()" />
                        <div *ngIf="checkTaxId" id="taxIdErrMsg" style="color: red;">Please enter more than 3
                            digits/letters.</div>
                    </div>
                    <div class="col-3">
                        <label for="sapId" class="form-label" [ngClass]="checkSapId ? 'red' : 'black'">SAP ID</label>
                        <input type="text" tabindex="10" class="form-control supplierName sapId" [(ngModel)]="searchCriteria.sapId"
                            placeholder="SAP ID" (input)="check()" />
                        <div *ngIf="checkSapId" id="sapIdErrMsg" style="color: red;">Please enter a 9 digits number.
                        </div>
                    </div>
                    <div class="col-3">
                        <label for="migratedTag" class="form-label">Migrated Tag</label>
                        <sh-multiple-dropdown tabindex="11" [dataSource]="migratedTag" [selectedKeys]="searchCriteria.migratedTag"
                            [placeholder]="'Migrated Tag'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'migratedTag')"
                            id="migratedTag"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="BI/BDA" class="form-label" [ngClass]="checkBI ? 'red' : 'black'">BI / BDA</label>
                        <sh-multiple-dropdown tabindex="12" [dataSource]="tempBiBda" [selectedKeys]="searchCriteria.biBda"
                            [placeholder]="'BI/BDA'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'biBda')"
                            id="biBda"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="statusTwo" class="form-label">TSM Status</label>
                        <sh-multiple-dropdown tabindex="13" [dataSource]="profileStatus" [selectedKeys]="searchCriteria.statusTwo"
                            [placeholder]="'TSM Status'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'statusTwo')"
                            id="statusTwo"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="domainName" class="form-label" [ngClass]="checkDomainName ? 'red' : 'black'">Domain
                            Name</label>
                        <input type="text" tabindex="14" class="form-control supplierName" [(ngModel)]="searchCriteria.domainName"
                            id="domainName" placeholder="Domain Name" (input)="check()" />
                        <div *ngIf="checkDomainName" id="domainNameErrMsg" style="color: red;">Please enter </div>
                    </div>
                    <div class="col-3">
                        <label for="saStatus" class="form-label">SA Status</label>
                        <sh-multiple-dropdown  tabindex="15" [dataSource]="tempAssessmentStatus" [selectedKeys]="searchCriteria.saStatus"
                            [placeholder]="'SA Status'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'saStatus')"
                            id="saStatus"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="onboardingStatus" class="form-label">Onboarding Status</label>
                        <sh-multiple-dropdown tabindex="16" [dataSource]="onboardingStatus" [selectedKeys]="searchCriteria.onboardingStatus"
                            [placeholder]="'Onboarding Status'" [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'onboardingStatus')"
                            id="onboardingStatus"></sh-multiple-dropdown>
                    </div>
                    <div class="col-3">
                        <label for="requestorEmail" class="form-label" [ngClass]="checkRequestorEmail ? 'red' : 'black'">Requestor Email</label>
                        <input type="email" tabindex="17" class="form-control supplierName" [(ngModel)]="searchCriteria.requestorEmail"
                            id="requestorEmail" placeholder="Enter requestor email" (input)="check()" />
                        <div *ngIf="checkRequestorEmail" style="color: red;">Please enter a valid email address </div>
                    </div>
                    <div class="col-12">
                        <div class="row justify-content-end">
                            <div class="col-4">
                                <div class="d-flex justify-content-end btn-margin">
                                    <sh-button class="cancel-btn" tabindex="18" style="margin-bottom: 10px; margin-right: 15px" [props]="advSearchCancelButton"></sh-button>
                                    <sh-button style="margin-bottom: 10px" tabindex="19" [props]="advSearchSearchButton"></sh-button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!--            <div class="form-group col-4">-->
                    <!--                <label for="companyCode">Company Code</label>-->
                    <!--                <input-->
                    <!--                    type="text"-->
                    <!--                    class="form-control"-->
                    <!--                    id="companyCode"-->
                    <!--                    [(ngModel)]="searchCriteria.companyCode"-->
                    <!--                />-->
                    <!--            </div>-->
                    <!--            <div class="form-group col-4">-->
                    <!--                <label for="sapID">SAP ID</label>-->
                    <!--                <input-->
                    <!--                    type="text"-->
                    <!--                    class="form-control"-->
                    <!--                    id="sapID"-->
                    <!--                    [(ngModel)]="searchCriteria.sapId"-->
                    <!--                />-->
                    <!--            </div>-->
                    <!--            <div class="form-group col-4">-->
                    <!--                <label for="spendCategoryGroup">Spend Category Group</label>-->
                    <!--                <sh-multiple-dropdown-->
                    <!--                    [dataSource]="tempSpendCategoryGroup"-->
                    <!--                    [selectedKeys]="searchCriteria.spendCategoryGroup"-->
                    <!--                    (saveSectionEvent)="-->
                    <!--                        saveSectionEvent($event, 'spendCategoryGroup')-->
                    <!--                    "-->
                    <!--                ></sh-multiple-dropdown>-->
                    <!--            </div>-->

                    <!-- <div class="col-3">
                        <label for="saStatus" class="form-label">Sustainability Assessment Status</label>
                        <sh-multiple-dropdown [dataSource]="tempAssessmentStatus"
                            [selectedKeys]="searchCriteria.saStatus" [placeholder]="'Sustainability Assessment Status'"
                            [placeholderClass]="'input-placeholder'"
                            (saveSectionEvent)="saveSectionEvent($event, 'saStatus')"
                            id="saStatus"></sh-multiple-dropdown>
                    </div> -->






                    <!--            <div class="form-group col-4">-->
                    <!--                <label for="assessmentStatus">Assessment Status</label>-->
                    <!--                <sh-multiple-dropdown-->
                    <!--                    [dataSource]="tempAssessmentStatus"-->
                    <!--                    [selectedKeys]="searchCriteria.assessmentStatus"-->
                    <!--                    [width]="'97%'"-->
                    <!--                    (saveSectionEvent)="-->
                    <!--                        saveSectionEvent($event, 'assessmentStatus')-->
                    <!--                    "-->
                    <!--                ></sh-multiple-dropdown>-->
                    <!--            </div>-->
                    <!--            <div class="form-group col-12">-->
                    <!--                <div class="break-line">-->
                    <!--                    <label class="bg-white pe-5">Credentials</label>-->
                    <!--                </div>-->
                    <!--            </div>-->
                    <!--            <div class="form-group col-12">-->
                    <!--                <div-->
                    <!--                    class="form-check form-check-inline me-5"-->
                    <!--                    *ngFor="let item of searchCriteria.credentials"-->
                    <!--                >-->
                    <!--                    <input-->
                    <!--                        class="form-check-input cursor-pointer"-->
                    <!--                        type="checkbox"-->
                    <!--                        id="{{ item.id }}"-->
                    <!--                        [(ngModel)]="item.value"-->
                    <!--                    />-->
                    <!--                    <label-->
                    <!--                        class="form-check-label cursor-pointer"-->
                    <!--                        for="{{ item.id }}"-->
                    <!--                    >-->
                    <!--                        {{ item.label }}-->
                    <!--                    </label>-->
                    <!--                </div>-->
                    <!--            </div>-->
                    <!--            <div class="form-group col-12">-->
                    <!--                <div class="break-line">-->
                    <!--                    <label class="bg-white pe-5">ESG Performance</label>-->
                    <!--                </div>-->
                    <!--            </div>-->
                    <!--            <div class="form-group col-12">-->
                    <!--                <div-->
                    <!--                    class="form-check form-check-inline me-5"-->
                    <!--                    *ngFor="let item of searchCriteria.ESGPerformance"-->
                    <!--                >-->
                    <!--                    <input-->
                    <!--                        class="form-check-input cursor-pointer"-->
                    <!--                        type="checkbox"-->
                    <!--                        id="{{ item.id }}"-->
                    <!--                        [(ngModel)]="item.value"-->
                    <!--                    />-->
                    <!--                    <label-->
                    <!--                        class="form-check-label cursor-pointer"-->
                    <!--                        for="{{ item.id }}"-->
                    <!--                    >-->
                    <!--                        {{ item.label }}-->
                    <!--                    </label>-->
                    <!--                </div>-->
                    <!--            </div>-->
                </div>
                <!-- <div class="d-flex flex-row-reverse">
                    <sh-button style="margin-bottom: 10px" [props]="advSearchSearchButton"></sh-button>
                    <sh-button class="cancel-btn" style="margin-bottom: 10px; margin-right: 30px"
                        [props]="advSearchCancelButton"></sh-button>
                </div> -->
            </div>

        </div>
        <div *ngIf="searchIf">
            <div
                style="margin-top: 6%;text-align:center;color: rgb(78, 78, 135);font-size: 14px; font-family: Graphik;font-weight: bold;">
                <span (click)="openPossibleScenariosPopUp()" style="cursor: pointer" tabindex="20" (keydown.enter)="openPossibleScenariosPopUp()">
                    <img src="assets/icons/icon-help-enabled.svg" alt="">
                    Check out the possible scenarios or functions within True Supplier Marketplace →
                </span>
            </div>
        </div>
        <!-- *ngIf="loading" -->
        <div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>