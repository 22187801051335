<div class="assesment mb-12 shadow rounded-0 position-relative border">
    <div class="row ms-3">
        <div class="col-6">
            <div class="title-container mt-3">
                <div class="title">Sustainability Assessment</div>
                <div class="sub_title_name">Understand supplier's sustainability performance. Select when onboarding is not needed.</div>
            </div>
        </div>
        <div class="col-6">
            <sh-button class="fl_right me-3 mt-3"  *ngIf="statusFlag" [props]="requestAssButton"></sh-button>
            <sh-button   class="fl_right me-3 mt-3" *ngIf="hasManageUsersValidRole && statusFlag" [props]="manageUsersButton"
            [ngbTooltip]="manageUsersButton?.tooltipText ? tipOption : null" placement="bottom-left"
                tooltipClass="manage-user-tooltip-class">
            </sh-button>
            <ng-template #tipOption>
            <div class="tip-inner-content">
                <div class="tip-header">
                    {{ "supplier.body.main.optionsCard.Tip" | translate }}
                </div>
                    {{ buttonJson.manageUsersTooltip }}
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="allAssessmentList.length > 0">
        <div class="row mt-3  ms-3">
            <div class="col-4">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="marketUnit_bvEsg">Select Market Unit</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input id="marketUnit_bvEsg"
                                [drapDownData]="tempMarketUnit" 
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'marketUnit')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="countryLocation">Select Country/ies</label>
                        <div class="col-7 ps-0">
                            <tsm-select-input
                                id="tempLocation"
                                [drapDownData]="tempLocation" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'countryLocation')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12 display-flex">
                        <label class="select-label" for="saStatus">Status</label>
                        <div class="col-8 ps-0">
                            <tsm-select-input id="saStatus"
                                [drapDownData]="tempAssessmentStatus" 
                                [refresh]="true"
                                [multiple] = "true"  
                                [placeholder]="'Select'"
                                (multipleDateSend)="saveSectionEvent($event, 'saStatus')">
                            </tsm-select-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3  ms-3 table-header">
            <div class="col-marketunit col-common">MARKET UNIT</div>
            <div class="col-country col-common">
                COUNTRIES SERVED
                <img class="icon-size icon-ml" placement="bottom-left" tooltipClass="manage-user-tooltip-class" triggers="click:blur" 
                 src="assets/icons/info-blue.svg" alt="icon-info" [ngbTooltip]="tipCountries" />
                <ng-template #tipCountries>
                    <div class="tip-inner-content">
                        {{tipMsgCountry}}
                    </div>
                </ng-template>
            </div>
            <div class="col-requestor col-common">REQUESTOR</div>
            <div class="col-requesteddate col-common">REQUESTED DATE</div>
            <div class="col-status col-common">
                STATUS
                <img class="icon-size icon-ml" alt="icon-info" src="assets/icons/info-blue.svg" (click)="showStatusPopup()" />
            </div>
            <div class="col-actions col-common">ACTIONS</div>
        </div>
        <div class="row ms-3 header-border"></div>
        <div class="table-overflow" *ngIf="exhibitAssessmentList.length > 0">
            <div *ngFor="let item of exhibitAssessmentList">
                <div class="row mt-3  ms-3">
                    <div class="col-marketunit col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class" triggers="hover"  
                        [ngbTooltip]="tipMarketUnit" container="body">
                            {{item.arrayMarketUnit.toString()}}
                        </div>
                        <ng-template #tipMarketUnit>
                            <div class="tip-inner-content">
                                {{item.arrayMarketUnit.toString()}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-country col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" tooltipClass="manage-user-tooltip-class" triggers="hover"  
                        [ngbTooltip]="tipServedCountry" container="body">
                            {{item.arrayServedCountry.join(', ')}}
                        </div>
                        <ng-template #tipServedCountry>
                            <div class="tip-inner-content">
                                {{item.arrayServedCountry.join(', ')}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-requestor col-common col-font-common">
                        <div *ngIf="item.requestor?.userName!='SYSTEM'">
                            <div class="text-overflow" placement="bottom-right" tooltipClass="manage-user-tooltip-class" triggers="hover"  
                            [ngbTooltip]="tipRequestor" container="body">
                                {{item.requestor?.userName}}
                            </div>
                            <ng-template #tipRequestor>
                                <div class="tip-inner-content">
                                    {{item.requestor?.userName}}
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf="item.requestor?.userName=='SYSTEM'">
                            {{item.requestor?.userName}}
                            <img *ngIf="item.showRequestorIcon" class="icon-size icon-ml" placement="bottom-right" tooltipClass="manage-user-tooltip-class" triggers="click:blur" 
                            src="assets/icons/info-blue.svg" alt="icon-info" [ngbTooltip]="tipRequestor" container="body" />
                            <ng-template #tipRequestor>
                                <div class="tip-inner-content">
                                    {{tipMsgOnboarding}}
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-requesteddate col-common col-font-common">{{item.creationDate | date: 'MM/dd/yyyy'}}</div>
                    <div class="col-status col-common">
                        <div class="width-100">
                            <div class="colstatus-complete colstatus-common" [ngClass]="item.saStatusLevel>=4?'colstatus-complete':'colstatus-process'">{{item.esgStatusLabel}}</div>
                            <div class="display-flex">
                                <div class="status-bar-common" [ngClass]="item.saStatusLevel==4?'status-bar-complete':item.saStatusLevel>=1?'status-bar-process':'status-bar-empty'" ></div>
                                <div class="status-bar-common" [ngClass]="item.saStatusLevel==4?'status-bar-complete':item.saStatusLevel>=2?'status-bar-process':'status-bar-empty'"></div>
                                <div class="status-bar-common" [ngClass]="item.saStatusLevel==4?'status-bar-complete':item.saStatusLevel>=3?'status-bar-process':'status-bar-empty'"></div>
                                <div class="status-bar-common" [ngClass]="item.saStatusLevel>=4?'status-bar-complete':'status-bar-empty'"></div>
                            </div>
                        </div>
                        <img *ngIf="isChildSupplier || waitingConfirmMapping" class="icon-ml icon-relation" placement="bottom-right" tooltipClass="manage-user-tooltip-class" triggers="click:blur" 
                            src="assets/icons/Relations_Icon.svg" alt="icon-info" [ngbTooltip]="tipPC" container="body" />
                        <ng-template #tipPC>
                            <div class="tip-inner-content">
                                {{tipMsgReleationship}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-actions col-common col-font-common">
                        <div class="width-100 display-flex">
                            <a class="a-button" (click)="onWithdrawButtonClick(item)" *ngIf="item.showWithdrawButton">Withdraw Request</a>
                            <a class="a-button" (click)="onRequestUpdateButtonClick(item)" *ngIf="item.showRequestUpdateButton && !item.isSupplierSendSA">Request Update</a>
                            <img class="icon-size icon-ml icon-mt icon-mr-1" src="assets/icons/info-blue.svg" alt="icon-info" *ngIf="item.IsReject" placement="bottom-right"
                            tooltipClass="manage-user-tooltip-class" triggers="click:blur" [ngbTooltip]="tipRejectedReason" container="body" />
                            <ng-template #tipRejectedReason>
                                <div class="tip-inner-content">
                                    <div class="tip-header">Rejected Reason</div>
                                    {{item.terminationData?.rejectReason}}
                                </div>
                            </ng-template>
                        </div>
                        <img src="assets/icons/Options_Icon.svg" alt="icon-info" class="icon-mt fl_right icon-option" *ngIf="item.showReviewButton"
                            tooltipClass="manage-user-tooltip-class" triggers="click:blur" [ngbTooltip]="tipActions" placement="bottom-right" container="body" />
                        <ng-template #tipActions>
                            <div class="tip-inner-content">
                                <div class="tip-header">Further Actions</div>
                                <a class="a-button" (click)="onReviewAssessmentButtonClick(item)" *ngIf="item.showReviewButton">{{isChildSupplier?"Review Parent Assessment":"View Details/Assessment"}}</a>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="row ms-3 header-border"></div>
            </div>
        </div>
    </div>
    <div class="row mb-4"></div>
  </div>