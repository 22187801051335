import { Component, OnInit } from "@angular/core";
import { Options } from "src/app/models/Options";
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { obfuscateEmail, SAStatus, SupplierRelationshipService, UserType } from "@lsl16/sustainability-shared-components";
import { SupplierHubService } from "src/app/services/supplierHub/supplier-hub.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { ReportService } from "src/app/services/authority/report.service";
import { isEmpty } from 'lodash';

@Component({
    selector: "tsm-base-supplier-profile",
    template: ""
})
export class BaseSupplierProfileComponent implements OnInit {
    selectedSupplierTsmId: string;
    supplier: GeneralSupplierType;
    supplierLoaded = false;
    error: boolean;
    userType: UserType;
    loginUserInfo: any;
    supplierTsmId: string;
    countryServed: string;
    questionnaireId: string;
    loadChildForRelationshipConfirmation: boolean = false;
    isSupplier: boolean = false;
    isSupplierParent: boolean = false;
    allMu: any;
    allCountry: any;
    allCategory: any;
    allSubCategory: any;
    hasConfirmedParent: boolean = false;
    parentSupplier: GeneralSupplierType;
    reviewData: any;
    isReviewDataAvailabe: boolean = false;
    buyerId: string = "c8aa5c00-588a-44d6-a08c-071dc9219cbe";

    esgAssessment: Options = {
        title: "Sustainability Assessment",
        imageUrl: "assets/images/actions-img.png"
    };
    onboarding: Options = {
        title: "Onboarding Form",
        imageUrl: "assets/images/onboarding.svg"
    };
    viewRelatedProfiles: Options = {
        title: "View Related Supplier Profiles",
        imageUrl: "assets/images/ipad-card-image.png"
    };
    tabs = [
        {
            id: "Actions",
            title: "Actions",
            icon: "assets/images/Actions-Active.svg",
            inactiveIcon: "assets/images/Actions-InActive.svg",
            statusIcon: '',
            active: true
        },
        {
            id: "Relationships",
            title: "Relationships",
            icon: "assets/images/Group-Active.svg",
            inactiveIcon: "assets/images/Group-InActive.svg",
            statusIcon: '',
            active: false,
            contentCount: undefined
        }
    ];
    
    private activityLogTab = {
        id: "ActivityLog",
        title: "ActivityLog",
        icon: "assets/images/Log-Active.svg",
        inactiveIcon: "assets/images/Log-InActive.svg",
        statusIcon: '',
        active: false,
        contentCount: undefined
    }


    private scoringTab = {
        id: "Scoring",
        title: "Scoring",
        icon: "assets/images/scoring-active.svg",
        inactiveIcon: "assets/images/scoring-inactive.svg",
        statusIcon: '',
        active: false
    };

    private suppliersTab = {
        id: "Suppliers",
        title: "Suppliers",
        icon: "assets/images/Suppliers-active.svg",
        inactiveIcon: "assets/images/Suppliers-inactive.svg",
        statusIcon: '',
        active: false
    };


    baseSupplierTranslations = {
        emptyPointOfContact: ""
    };
    SCOPE3_KEY_SUPPLIER: string = "SCOPE3_KEY_SUPPLIER";
    allMuCustom= [];

    constructor(
        public supplierGeneralProfileService: SupplierGeneralProfileService,
        public authService: AuthService,
        public supplierHubService: SupplierHubService,
        public reportService: ReportService,
        protected relationshipService: SupplierRelationshipService
    ) {
        this.isSupplier = this.authService.getRole() === "supplier";
        this.handleTranslation();
    }

    ngOnInit() {

    }

    getAll = async (): Promise<void> => {
        this.allCountry = JSON.parse(localStorage.getItem('countryList')).servedCountryList
        this.allMu = JSON.parse(localStorage.getItem('marketUnitList'))
        this.allCategory = JSON.parse(localStorage.getItem('categoryList'))
        this.allSubCategory = JSON.parse(localStorage.getItem('subCategoryList'))
        // Code to temporarily remove LATAM market unit list from Onboard and Risk Assessment
        for (let i = 0; i < this.allMu.length; i++) {
            if (this.allMu[i].Code !== 'LATAM') {
                this.allMuCustom.push(this.allMu[i])
            }
        }
    }
    initialiseSupplier = async (): Promise<void> => {
        if (this.isSupplier && !this.loadChildForRelationshipConfirmation) {
            this.supplierTsmId = this.authService.getLoginUser().tsmId;
        } else {
            this.supplierTsmId = this.selectedSupplierTsmId;
        }
        const isAwaitingForParentConfirmation = await this.isAwaitingForParentConfirmation();
        if (this.authService.getToken()) {
            let response = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
            console.log("getSupplierById response base-supplier-comp", response);
            if (!response) {
                this.error = true;
            } else {
                this.supplier = {
                    ...response,
                    companyLogo: "assets/icons/business-24px.svg",
                    countryServedDescription: [],
                    pointOfContactEmail: this.fetchPointOfContactEmail(response.supplierAdmin)
                };
                
                this.supplierLoaded = true;
                this.hasConfirmedParent = !isEmpty(this.supplier.parentTsmId) && this.supplier.parentTsmId !== this.supplier.supplierTsmId;
            }
            if (Array.isArray(response.companyLogo) && response.companyLogo.length) {
                const companyLogo = response?.companyLogo[0].filePath;
                await this.getcompanyLogoFile(companyLogo);
            } else {
                if (this.isSupplier && document.getElementsByClassName('supplier-img')[0]) {
                    let supplierImageNode = document.getElementsByClassName('supplier-img')[0].getElementsByTagName('img')[0];
                    this.supplier.imgUrlForPreview = "assets/icons/business-24px.svg"
                    supplierImageNode.src = "assets/icons/business-24px.svg";
                    supplierImageNode.width = 100;
                    supplierImageNode.height = 100;
                }
            }
        }
        if (!isAwaitingForParentConfirmation) {
            this.isSupplierParent = this.supplier
                && (isEmpty(this.supplier.parentTsmId) || this.supplier.supplierTsmId === this.supplier.parentTsmId);
            if (this.isSupplierParent) {
                this.relationshipService.getSubsidiarySuppliersCountEvent().subscribe(next => this.handleCountEvent(next));
                await this.relationshipService.getSubsidiarySuppliersCount(this.supplier.supplierTsmId);
            }
        } else {
            this.isSupplierParent = false;
        }
        if (!this.isSupplierParent) {
            this.handleCountEvent(0);
        }

        if (this.supplier && !this.supplier.indirect) {
            this.addSuppliersTab();
        }
        else {
            this.removeSuppliersTab()
        }


    };


    handleCountEvent = (count): void => {
        const relationshipTab = this.tabs.find(x => x.id === "Relationships");
        if (relationshipTab) {
            if (count > 0) {
                this.isSupplierParent = true;
                relationshipTab.contentCount = count;
            } else {
                this.isSupplierParent = false;
                relationshipTab.contentCount = undefined;
            }
        }
    }

    initialiseSupplierWithCountryServed = async (editCountryServed): Promise<void> => {
        if (this.isSupplier) {
            this.supplierTsmId = this.authService.getLoginUser().tsmId;
        } else {
            this.supplierTsmId = this.selectedSupplierTsmId;
        }
        if (this.authService.getToken()) {
            let response = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
            if (!response) {
                this.error = true;
            } else {
                this.supplier = {
                    ...response,
                    companyLogo: "assets/icons/business-24px.svg",
                    countryServedDescription: [],
                    pointOfContactEmail: this.fetchPointOfContactEmail(response.supplierAdmin),
                    editCountryServed: editCountryServed
                };
                this.supplierLoaded = true;
            }
            if (Array.isArray(response.companyLogo) && response.companyLogo.length) {
                const companyLogo = response?.companyLogo[0].filePath;
                await this.getcompanyLogoFile(companyLogo);
            } else {
                if (this.isSupplier && document.getElementsByClassName('supplier-img')[0]) {
                    let supplierImageNode = document.getElementsByClassName('supplier-img')[0].getElementsByTagName('img')[0];
                    this.supplier.imgUrlForPreview = "assets/icons/business-24px.svg"
                    supplierImageNode.src = "assets/icons/business-24px.svg";
                    supplierImageNode.width = 100;
                    supplierImageNode.height = 100;
                }
            }
        }
    };

    fetchPointOfContactEmail = (supplierAdmin): string => {
        if (supplierAdmin) {
            if (this.isSupplier) {
                return supplierAdmin.userName;
            }
            return obfuscateEmail(supplierAdmin.userName);
        }
        return this.baseSupplierTranslations.emptyPointOfContact;
    };

    async fetchParentData(): Promise<void> {
        if (this.hasConfirmedParent) {
            let response = await this.supplierGeneralProfileService.getSupplierById(this.supplier.parentTsmId);
            this.parentSupplier = response;
        }
    }

    handleAccessNow = (): void => {
        // key1 is supplierTsmId
        const key1 = this.supplier.supplierTsmId;

        // key2 is countryServed
        // countryServerd must be in country code format, hardcoding for now
        // const key2 = this.supplier.countryServed[0]
        const key2 = "UK";

        const redirectUrl = this.supplierHubService.getProfilesCardPageRedirectUrl(key1, key2);

        window.open(redirectUrl, "_blank");
    };

    blobToDataURI(blob, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function (e) {
            callback(e.target.result);
        }
    };


    getcompanyLogoFile = async (fileKey): Promise<void> => {

        let blob = await this.reportService.downloadImageFile(fileKey);

        this.blobToDataURI(blob, (result) => {
            let supplierImageNode = document.getElementsByClassName('supplier-img')[0].getElementsByTagName('img')[0];
            this.supplier.imgUrlForPreview = result
            supplierImageNode.src = result;
            supplierImageNode.width = 100;
            supplierImageNode.height = 100;
        })
    }

    protected async isAwaitingForParentConfirmation(): Promise<boolean> {
        const relation = await this.relationshipService.getParentChildRelation(this.supplierTsmId);
        return relation?.status === "AwaitingParentConfirmation";
    }

    collectReviewData() {
        this.reviewData = (this.parentSupplier) ? this.parentSupplier.review : this.supplier?.review;
        this.isReviewDataAvailabe = (this.reviewData && Object.keys(this.reviewData).length > 0);
        this.addOrRemoveScoringTab();
    }

    protected addOrRemoveScoringTab() {
        if (this.isReviewDataAvailabe) {
            this.addScoringTab();
        } else {
            this.removeScoringTab();
        }

        this.selectTab();
    }

    private addScoringTab() {
        if (this.isScoringTabExists()) { return; }
        this.tabs.push({ ...this.scoringTab });
    }


    private removeScoringTab() {
        if (this.isScoringTabExists()) {
            this.tabs = this.tabs.filter(tab => tab.id !== 'Scoring');
        }
    }

    private isScoringTabExists() {
        return this.tabs.findIndex((t) => t.id === "Scoring") >= 0;
    }

    private selectTab() {
        const curTabAvailable = this.tabs.findIndex((t) => t.active === true) >= 0;
        if (!curTabAvailable && this.tabs.length > 0) {
            // no tab was selected > select first in list as default selection
            this.tabs[0].active = true;
        }
    }

    private addSuppliersTab() {
        if (this.isSuppliersTabExists()) { return; }
        this.tabs.push({ ...this.suppliersTab });
    }

    private removeSuppliersTab() {
        if (this.isSuppliersTabExists()) {
            this.tabs = this.tabs.filter(tab => tab.id !== 'Suppliers');
        }
    }

    private isSuppliersTabExists() {
        return this.tabs.findIndex((t) => t.id === "Suppliers") >= 0;
    }

    addActivityLogTab(userType: string) {
    
        if (userType == "buyer" || userType == "supplier") {
            if (this.isActivityLogTabExists()) { return; }
            this.tabs.push({ ...this.activityLogTab });
        }
    }


    private isActivityLogTabExists() {
        return this.tabs.findIndex((t) => t.id === "ActivityLog") >= 0;
    }


    private handleTranslation() {
        if (localStorage.getItem('multiLanguage')) {
            const multilanguageJson = JSON.parse(
                localStorage.getItem('multiLanguage')
            );
            if (multilanguageJson.body) {
                this.baseSupplierTranslations = multilanguageJson.body.main.baseSupplierProfile;
            }
        }
    }

    canReviewAssessment(esgStatus: string): boolean {
        return esgStatus === SAStatus.pendingReview
            || esgStatus === SAStatus.updateRequired
            || esgStatus === SAStatus.submittedScored;
    }

    protected isScope3Supplier(supplier: GeneralSupplierType): any {
        return supplier?.classification ? supplier?.classification.includes(this.SCOPE3_KEY_SUPPLIER) : false;
    }
}
