import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { ButtonPropsType, SAStatus } from "@lsl16/sustainability-shared-components";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { CategoryFilterType, SearchTriggerEnum, AdvancedSearchType } from "../../models/Search";
import { responseEnum } from "../../../utilities/response-enum";
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { PossibleScenariosConfig } from "../pop-up-possible-scenarios/possibleScenarios";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpPossibleScenariosComponent } from "../pop-up-possible-scenarios/pop-up-possible-scenarios.component";

@Component({
    selector: "tsm-new-search",
    templateUrl: "./new-search.component.html",
    styleUrls: ["./new-search.component.sass"]
})
export class NewSearchComponent implements OnInit {
    @Input() page: number;
    @Input() suppliers: GeneralSupplierType[];
    @Input() categoryFilters: CategoryFilterType[];
    @Input() lastQuery: string;
    @Output() private show = new EventEmitter();
    @Output() private hide = new EventEmitter();
    @Output()
    changeCurrentPage: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    supplierSearchResultsEventEmitter: EventEmitter<any> = new EventEmitter();

    @Output()
    addNewSupplierEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    restPage: EventEmitter<any> = new EventEmitter<any>();
    advSearchSearchButton: ButtonPropsType;
    advSearchCancelButton: ButtonPropsType;
    isAdvanceSearchOpen: boolean = false;
    checkNumber: boolean = false;
    checkSapId: boolean = false;
    checkTaxId: boolean = false;
    errorState: boolean = false;
    checkInternational: boolean = false;
    inviteSupplierButton: ButtonPropsType;
    invitesupplierAuth: boolean = false;
    tempLocation: any;
    loading: boolean = false;
    searchIf: boolean = true;
    number: number = 0;
    DUNSnumber: any;
    checkSupplierName: boolean = false;
    checkSingleSearch: boolean = false;
    isDisabled: boolean = true;
    checkDomainName: boolean = false;
    checkTSMId: boolean = false;
    checkBI: boolean = false;
    checkRequestorEmail: boolean = false;

    tempBiBda:any =[
        {
            Code: "BI",
            Text: "BI"
        },
        {
            Code: "BDA",
            Text: "BDA"
        },
        {
            Code: "No",
            Text: "No"
        }
    ]

    profileStatus: any = [
        {
            Code: "Active",
            Text: "Active"
        },
        {
            Code: "Pending",
            Text: "Pending"
        },
        {
            Code: "Terminated",
            Text: "Terminated"
        }
    ];
    tempAssessmentStatus: any = [
        {
            Code: SAStatus.awaitingAcceptance,
            Text: "Awaiting acceptance"
        }, {
            Code: SAStatus.awaitingSubmission,
            Text: "Awaiting submission"
        }, {
            Code: SAStatus.pendingReview,
            Text: "Pending review"
        }, {
            Code: SAStatus.updateRequired,
            Text: "Update required"
        }, {
            Code: SAStatus.submittedScored,
            Text: "Validation completed"
        }
    ];
    servedData: any = [];
    activeCountryData: any = [];
    companyCodeData: any = [];
    tempcompanyCodeData: any = [];
    data: any = {
        countryLocation: [],
        tsmStatus: [],
        singleSearch: "",
        dunsNumber: "",
        countryOfDelivery: [],
        saStatus: [],
        page: "",
        pageSize: "",
        taxId: "",
        sapId: "",
        companyCode: [],
        domainName:"",
        category:[],
        onboardingStatus:[],
        tsmId:"",
        biBda:[],
        migratedTag:[],
        requestorEmail:""
    };
    searchCriteria: any = {
        countryLocationTwo: [],
        statusTwo: ["Active","Pending"],
        singleSearch: "",
        duns: "",
        countryServedTwo: [],
        saStatus: [],
        page: "",
        pageSize: "",
        taxId: "",
        sapId: "",
        companyCode: [],
        International:"",
        domainName:"",
        category:[],
        onboardingStatus:[],
        tsmId:"",
        biBda:[],
        migratedTag:[],
        requestorEmail:""
    };
    searchedCriteria: any = {
        countryLocationTwo: [],
        statusTwo: [],
        singleSearch: "",
        duns: "",
        countryServedTwo: [],
        saStatus: [],
        page: "",
        pageSize: "",
        taxId: "",
        sapId: "",
        companyCode: [],
        International:"",
        domainName:"",
        category:[],
        onboardingStatus:[],
        tsmId:"",
        biBda:[],
        migratedTag:[],
        requestorEmail:""
    };
    possibleScenariosConfig: PossibleScenariosConfig = {
        type: "possible-scenarios",
        options: {},
        size: "lg",
        centered: false,
        scrollable: true,
        windowClass: "modal-possible-scenarios"
    };
    category: any = [];

    onboardingStatus: any = [
        {
            Code: "Migrated/Not Changed",
            Text: "Migrated/Not Changed"
        },
        {
            Code: "Pending Supplier Action",
            Text: "Pending Supplier Action"
        },
        {
            Code: "Pending Supplier Submission",
            Text: "Pending Supplier Submission"
        },
        {
            Code: "Pending Requestor Submission",
            Text: "Pending Requestor Submission"
        },
        {
            Code: "Pending Internal Review",
            Text: "Pending Internal Review"
        },
        {
            Code: "Onboarded",
            Text: "Onboarded"
        },
        {
            Code: "Cancelled",
            Text: "Cancelled"
        },
    ];

    migratedTag: any = [
        {
            Code: "Migrated",
            Text: "Migrated"
        },
        {
            Code: "Created in TSM",
            Text: "Created in TSM"
        },
    ]

    constructor(private supplierService: SupplierGeneralProfileService,
        public ngbModalService: NgbModal,
        private HttpService: HttpService) { }

    async ngOnInit(): Promise<void> {
        if (sessionStorage.getItem('useCache') === 'true' || sessionStorage.getItem("needCache") === "true") {
            const cacheParams = JSON.parse(sessionStorage.getItem('searchParams'))
            const page = cacheParams.page
            this.isAdvanceSearchOpen = cacheParams.isAdvanceSearchOpen
            delete cacheParams.isAdvanceSearchOpen
            delete cacheParams.page
            this.searchCriteria = { ...this.searchCriteria, ...cacheParams }
            if (page !== 1) {
                this.changeCurrentPage.emit(page)
            } else {
                this.search(SearchTriggerEnum.UseCacheSearchTrigger)
            }
            if (sessionStorage.getItem("needCache") === "true") {
                sessionStorage.setItem('useCache', 'true')
                sessionStorage.setItem('needCache', 'false')
            }
        }
        if (sessionStorage.getItem('useCache') !== 'true') {
            setTimeout(() => {
                // word-wrap:break-word
                let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
                if (tsmRoot) {
                    tsmRoot.style.background = "white";
                }

                // let subheader = <HTMLElement> document.getElementsByClassName("subheader-container")[0];
                // if (subheader) {
                //     subheader.style.display = "none";
                // }
                // multi-drop-input

            }, 10);
        }
        const servedDataUrl = `${environment.tsmBackendServiceURL}/countries/activeCountryServed`;
        const loccationurl = `${environment.tsmBackendServiceURL}/countries`;

        this.setButtonProps();
        this.servedData = await this.HttpService.GetPromise(servedDataUrl);
        this.filterCountry(this.servedData);
        const countriesData = await this.HttpService.GetPromise(loccationurl);
        this.tempLocation = countriesData;
        const categoryList = localStorage.getItem("categoryList");
        this.category = JSON.parse(categoryList);
    }

    ngOnDestroy() {
        let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.style.background = "#f2f2f2";
        }
        // let subheader = <HTMLElement> document.getElementsByClassName("subheader-container")[0];
        // subheader.style.display = "inline-table";
        window.scrollTo(document.body.scrollHeight, 0)
    }

    filterCountry(servedData) {
        servedData.forEach(element => {
            let state = element.countryServedEnable;
            let code = element?.tsmCode === "CA" ? "Ca" : element?.tsmCode;
            if (state == true) {
                let country = {
                    'Code': code,
                    'Text': element.countryServedName
                };
                this.activeCountryData.push(country);
            }
        });
        this.activeCountryData.sort(function (obj1, obj2) {
            var val1 = obj1.Text;
            var val2 = obj2.Text;
            if (val1 > val2) {
                return 1;
            } else if (val1 < val2) {
                return -1;
            } else {
                return 0;
            }
        });
    }
    /**
     * Description: toggles isAdvancedSearch to display and hide advanced search options
     */
    toggleAdvancedSearch() {
        this.isAdvanceSearchOpen = !this.isAdvanceSearchOpen;
        this.checkNumber, this.checkSapId, this.checkTaxId, this.checkRequestorEmail = false;
        if (this.isAdvanceSearchOpen) {
            setTimeout(() => {
                let cancelBtn = <HTMLElement>document.getElementsByClassName("cancel-btn")[0].children[0];
                cancelBtn.style.width = "120px"
                cancelBtn.style.height = "40px"
                let inputDrop = <HTMLElement>document.getElementsByClassName("multi-drop-input")[0];
                inputDrop.setAttribute("id", "dropwown-input-company-location");
                let inputDrop2 = <HTMLElement>document.getElementsByClassName("multi-drop-input")[1];
                inputDrop2.setAttribute("id", "dropwown-input-sustainability-assessment-status");
                let inputDrop3 = <HTMLElement>document.getElementsByClassName("multi-drop-input")[2];
                inputDrop3.setAttribute("id", "dropwown-input-profile-status");
                let inputDrop4 = <HTMLElement>document.getElementsByClassName("multi-drop-input")[3];
                inputDrop4.setAttribute("id", "dropwown-input-country-served");
                let inputContainer = <HTMLElement>document.getElementsByClassName("multi-drop-input")[0].parentElement;
                inputContainer.setAttribute("id", "dropwown-input-container-company-location");
                let inputContainer2 = <HTMLElement>document.getElementsByClassName("multi-drop-input")[1].parentElement;
                inputContainer2.setAttribute("id", "dropwown-input-container-sustainability-assessment-status");
                let inputContainer3 = <HTMLElement>document.getElementsByClassName("multi-drop-input")[2].parentElement;
                inputContainer3.setAttribute("id", "dropwown-input-container-profile-status");
                let inputContainer4 = <HTMLElement>document.getElementsByClassName("multi-drop-input")[3].parentElement;
                inputContainer4.setAttribute("id", "dropwown-input-container-country-served");
                let elements = <HTMLElement>document.getElementsByTagName("tsm-add-new-supplier")[0];
                if (elements) {
                    elements = <HTMLElement>document.getElementsByTagName("tsm-add-new-supplier")[0].firstChild;
                    elements.style.bottom = "31px";
                }
            }, 1);
        }
        if (!this.searchIf) {
            setTimeout(() => {
                // word-wrap:break-word
                let advancedcontent = <HTMLElement>document.getElementsByClassName("advanced-search")[0];

                if (advancedcontent) {
                    advancedcontent.classList.remove("advanced-search-content")
                    advancedcontent.classList.add("advanced-search-content-two")
                }

                let elements = <HTMLElement>document.getElementsByTagName("tsm-add-new-supplier")[0].firstChild;
                let scrollBottom = this.scollPostion().height - window.innerHeight - this.scollPostion().top;

                if (this.scollPostion().height < 870 && this.scollPostion().height > 700 && this.scollPostion().top > 144 || scrollBottom < 1) {
                    elements.style.bottom = "103px";
                } else {
                    elements.style.bottom = "31px";
                }
            }, 10);
        }
        if (!this.isAdvanceSearchOpen) {
            this.searchCriteria.countryLocationTwo = []
            this.searchCriteria.saStatus = []
            this.searchCriteria.statusTwo = []
            this.searchCriteria.countryServedTwo = []
            this.searchCriteria.duns = ""
            this.searchCriteria.International = ""
            this.searchCriteria.taxId = ""
            this.searchCriteria.sapId = ""
            this.searchCriteria.companyCode = []
        }
    }
    // if(!/^\d+$/.test(searchCriteria.duns))校验
    check() {
        const reg = /^[0-9]{9}$/
        const emailReg = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/
        const taxReg = /^[A-Za-z0-9]{11}$/
        const result = reg.test(this.searchCriteria.duns)
        const taxIdresult = taxReg.test(this.searchCriteria.taxId)
        const sapIdresult = reg.test(this.searchCriteria.sapId)
        const requestorEmailresult = emailReg.test(this.searchCriteria.requestorEmail)
        console.log(result)

        // this.checkSupplierNameLength();
        this.checkSingleSearchLength();
        if (result || this.searchCriteria.duns === "") {
            this.checkNumber = false
        } else {
            this.checkNumber = true;
        }
        if (taxIdresult || this.searchCriteria.taxId === "") {
            this.checkTaxId = false;
        } else {
            this.checkTaxIDLength();
        }
        if (sapIdresult || this.searchCriteria.sapId === "") {
            this.checkSapId = false;
        } else {
            this.checkSapId = true;
        }
        if (requestorEmailresult || this.searchCriteria.requestorEmail === "") {
            this.checkRequestorEmail = false;
        } else {
            this.checkRequestorEmail = true;
        }
    }

    checkTaxIDLength() {
        this.checkTaxId = (this.searchCriteria.taxId.length < 3) ? true : false
    }
    /**
     * Description: sets filter when user changes filter tab in UI.
     * Triggers search function with new filter to fetch correct results.
     * @Params category filter
     */
    changeCategory(filter) {
        if (this.searchCriteria.categoryFilter !== filter) {
            this.searchCriteria.categoryFilter = filter;
            this.search(SearchTriggerEnum.CategoryTrigger);
        }
    }

    /**
     * Description: invokes search function if user presses enter
     * @Params key down event
     */
    async searchKeyDown(event): Promise<void> {
        this.clearError();
        if (event.keyCode === 13) {
            // enter:13
            await this.search(SearchTriggerEnum.QuickSearchTrigger);
        }
    }

    /**
     * Description: checks for changes in page number and triggers search function to fetch corresponding results
     * @Params changes
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.page && !changes.page.firstChange) {
            console.log(changes.page)
            console.log(SearchTriggerEnum.PageChangeTrigger)
            this.search(SearchTriggerEnum.PageChangeTrigger);
        }
    }

    /**
     * Description: adds criteria to #searchedCriteria
     * invokes #generateQueryString() to get query string
     * executes API call based on query string and #page number.
     * @Params trigger: trigger of search function
     */
    backPage() {
        this.searchIf = true;
        this.hide.emit()
        let searchtextbox = <HTMLElement>document.getElementsByClassName("search-textbox")[0];
        searchtextbox.classList.add("search-button")
        searchtextbox.classList.remove("search-buttonTwo")
        let advanceSearch = <HTMLElement>document.getElementsByClassName("advanced-search-supplier")[0];
        // advanced-search-btn
        advanceSearch.classList.add("advanced-search-button")
        advanceSearch.classList.add("search-button")
        // advanceSearch.classList.add("search-buttonTwo")
        let divsearch = <HTMLElement>document.getElementsByClassName("search-content")[0];
        divsearch.classList.remove("search-content-two")
        let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.style.background = "white";
        }
        if (this.isAdvanceSearchOpen) {
            setTimeout(() => {
                // word-wrap:break-word
                let advancedcontent = <HTMLElement>document.getElementsByClassName("advanced-search")[0];
                advancedcontent.classList.add("advanced-search-content")
                advancedcontent.classList.remove("advanced-search-content-two")
            }, 10);
        }
    }
    checkcompanyName() {
        if (this.searchCriteria.International.length > 255) {
            this.checkInternational = true
        } else {
            this.checkInternational = false
        }
        // this.checkSupplierNameLength();
        this.checkSingleSearchLength();
    }
    checkSupplierNameLength(event = undefined) {
        if (event) {
            this.searchCriteria.supplierName = event.target.value;
        }
        const supplierName = this.searchCriteria.supplierName.trim();
        this.checkSupplierName = supplierName.length < 3 ? true : false;
        if (this.searchCriteria.countryLocationTwo != undefined && this.searchCriteria.countryLocationTwo.length > 0) {
            this.checkSupplierName = false;
        }
        if (this.searchCriteria.saStatus != undefined && this.searchCriteria.saStatus.length > 0) {
            this.checkSupplierName = false;
        }
        if (this.searchCriteria.statusTwo != undefined && this.searchCriteria.statusTwo.length > 0) {
            this.checkSupplierName = false;
        }
        if (this.searchCriteria.countryServedTwo != undefined && this.searchCriteria.countryServedTwo.length > 0) {
            this.checkSupplierName = false;
            this.isDisabled = false;
        }
        if (this.searchCriteria.International.length >= 1) {
            this.checkSupplierName = false;
        }
        if (this.searchCriteria.duns.length >= 1) {
            this.checkSupplierName = false;
        }
        if (this.searchCriteria.taxId.length >= 1) {
            this.checkSupplierName = false;
        } if (this.searchCriteria.sapId.length >= 1) {
            this.checkSupplierName = false;
        }
        if (this.searchCriteria.companyCode != undefined && this.searchCriteria.companyCode.length > 0) {
            this.checkSupplierName = false;
        }
    }


    checkSingleSearchLength(event = undefined) {
        if (event) {
            this.searchCriteria.singleSearch = event.target.value;
        }
        const singleSearch = this.searchCriteria.singleSearch.trim();
        this.checkSingleSearch = singleSearch.length < 3 ? true : false;
        if (this.searchCriteria.countryLocationTwo != undefined && this.searchCriteria.countryLocationTwo.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.saStatus != undefined && this.searchCriteria.saStatus.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.statusTwo != undefined && this.searchCriteria.statusTwo.length > 0 && this.isAdvanceSearchOpen) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.countryServedTwo != undefined && this.searchCriteria.countryServedTwo.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.International.length >= 1) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.duns.length >= 1) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.taxId.length >= 1) {
            this.checkSingleSearch = false;
        } 
        if (this.searchCriteria.sapId.length >= 1) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.companyCode != undefined && this.searchCriteria.companyCode.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.domainName.length >= 1) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.onboardingStatus != undefined && this.searchCriteria.onboardingStatus.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.tsmId.length >= 1) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.biBda != undefined && this.searchCriteria.biBda.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.category != undefined && this.searchCriteria.category.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.migratedTag != undefined && this.searchCriteria.migratedTag.length > 0) {
            this.checkSingleSearch = false;
        }
        if (this.searchCriteria.requestorEmail.length >= 1) {
            this.checkSingleSearch = false;
        }
    }


    async search(trigger: SearchTriggerEnum): Promise<void> {
        this.checkSingleSearchLength();
        
        if (this.checkSingleSearch) {
            return;
        }
        if (this.checkNumber || this.checkInternational || this.checkTaxId || this.checkSapId) {
            return
        }
        // this.DUNSnumber = this.searchCriteria.supplierName
        // if (this.searchCriteria.supplierName == "") {
        //     this.DUNSnumber = "All companies"
        // }
        this.show.emit()
        
        this.loading = true;
        this.searchIf = false;
        let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.style.background = "#f2f2f2";
            //Click event is not propageted for custom objects, but needed for multi-dropdowns
            tsmRoot.click();
        }
        let searchtextbox = <HTMLElement>document.getElementsByClassName("search-textbox")[0];
        searchtextbox.classList.remove("search-button")
        searchtextbox.classList.add("search-buttonTwo")
        let advanceSearch = <HTMLElement>document.getElementsByClassName("advanced-search-supplier")[0];
        // advanced-search-btn
        advanceSearch.classList.remove("advanced-search-button")
        advanceSearch.classList.remove("search-button")
        // advanceSearch.classList.add("search-buttonTwo")
        let divsearch = <HTMLElement>document.getElementsByClassName("search-content")[0];
        divsearch.classList.add("search-content-two")
        if (this.isAdvanceSearchOpen) {
            setTimeout(() => {
                // word-wrap:break-word
                let advancedcontent = <HTMLElement>document.getElementsByClassName("advanced-search")[0];
                advancedcontent.classList.remove("advanced-search-content")
                advancedcontent.classList.add("advanced-search-content-two")
            }, 10);
        }
        if (trigger === SearchTriggerEnum.CategoryTrigger) {
            this.searchedCriteria.categoryFilter = this.searchCriteria.categoryFilter;
            this.resetPageNumber();
        } else if (trigger === SearchTriggerEnum.QuickSearchTrigger) {
            // console.log("I am called quick search");
            
            // if (!this.searchCriteria.singleSearch) {
            //     const searchResults = {
            //         type: "emptySearch"
            //     };
            //     this.supplierSearchResultsEventEmitter.emit(searchResults);
            //     this.searchedCriteria = {
            //         singleSearch: "",
            //         duns:"",
            //     };
            //     return;
            // } else {
            //     if (this.searchCriteria.singleSearch.length === 1) {
            //         this.searchedCriteria.singleSearch = this.searchCriteria.singleSearch;
            //         return;
            //     }
            // }
            // this.searchedCriteria.singleSearch = this.searchCriteria.singleSearch;
            this.searchedCriteria = JSON.parse(JSON.stringify(this.searchCriteria));
            // this.resetPageNumber();
            // this.restPage.emit()
        } else if (trigger === SearchTriggerEnum.FirstLoadTrigger) {
            this.searchedCriteria = JSON.parse(JSON.stringify(this.searchCriteria));
        } else if (trigger === SearchTriggerEnum.AdvancedSearchTrigger) {
            this.searchedCriteria = JSON.parse(JSON.stringify(this.searchCriteria));
        }

        // TODO: Temp solution; remove when implementing Advanced Search

        const query: string = this.generateQueryString();
        const url = `${environment.tsmBackendServiceURL}/search/supplier/newAdvance`;
        this.data.countryLocation = this.searchCriteria.countryLocationTwo;
        this.data.tsmStatus = this.searchCriteria.statusTwo;
        this.data.singleSearch = this.searchCriteria.singleSearch.trim();
        this.data.dunsNumber = this.searchCriteria.duns;
        this.data.countryOfDelivery = this.searchCriteria.countryServedTwo;
        this.data.saStatus = this.searchCriteria.saStatus;
        this.data.internationalCompanyName = this.searchCriteria.International.trim();
        this.data.taxId = this.searchCriteria.taxId;
        this.data.sapId = this.searchCriteria.sapId;
        this.data.category = this.searchCriteria.category
        this.data.domainName = this.searchCriteria.domainName
        this.data.onboardingStatus = this.searchCriteria.onboardingStatus,
        this.data.tsmId=this.searchCriteria.tsmId
        this.data.migratedTag=this.searchCriteria.migratedTag
        this.data.biBda=this.searchCriteria.biBda
        this.data.requestorEmail = this.searchCriteria.requestorEmail
        let companyCodeDigit = [];
        if (this.searchCriteria.companyCode?.length) {
            this.searchCriteria.companyCode.forEach(element => {
                var digits = element.split(" ");
                companyCodeDigit.push(digits[0]);
            });
        }
        this.data.companyCode = companyCodeDigit;
        if (!this.searchCriteria.companyCode) {
            this.data.companyCode = []
        }
        if (!this.searchCriteria.countryServedTwo) {
            this.data.countryServed = []
        }
        if (!this.searchCriteria.statusTwo) {
            this.data.status = []
        }
        if (!this.searchCriteria.countryLocationTwo) {
            this.data.countryLocation = []
        }
        if (!this.searchCriteria.saStatus) {
            this.data.saStatus = []
        }
        if (this.page) {
            this.data.page = this.page
        } 
        if (query !== this.lastQuery) {
            this.lastQuery = query;
            const searchSupplierResponse: any = await this.HttpService.PostPromise(url, this.data);
            sessionStorage.setItem('useCache', 'true')
            sessionStorage.setItem('searchParams', JSON.stringify({ page: this.page, isAdvanceSearchOpen: this.isAdvanceSearchOpen, ...this.searchCriteria }))
            const searchResults = {
                type: "",
                suppliers: [],
                pages: 0
            };
            // judge response: 200, 404, others
            this.loading = false;
            setTimeout(() => {
                window.scrollTo(document.body.scrollHeight, JSON.parse(sessionStorage.getItem('dashBoardSearchScrollTop')) || 0)
                sessionStorage.setItem('dashBoardSearchScrollTop', '0')
            }, 0);
            if (searchSupplierResponse) {
                // 404
                if (searchSupplierResponse["code"] === responseEnum.search_supplier_no_result.code) {
                    this.suppliers = [];
                    searchResults.type = "searchSuccess";
                    this.supplierSearchResultsEventEmitter.emit(searchResults);
                    let elements = <HTMLElement>document.getElementsByTagName("tsm-add-new-supplier")[0].firstChild;
                    setTimeout(() => {
                        let scrollBottom = this.scollPostion().height - window.innerHeight - this.scollPostion().top;
                        if (this.scollPostion().height < 870 && this.scollPostion().height > 700 && this.scollPostion().top > 144 || scrollBottom < 1) {
                            elements.style.bottom = "103px";
                        } else {
                            elements.style.bottom = "31px";
                        }
                    }, 1);
                } else if (searchSupplierResponse["code"]) {
                    // other http status
                    this.handleError();
                    this.suppliers = [];
                    searchResults.type = "searchFailed";
                    this.supplierSearchResultsEventEmitter.emit(searchResults);
                } else if (searchSupplierResponse["Forbidden"]) {
                    // the user has no permissions to execute the search
                    this.suppliers = [];
                } else {
                    // 200
                    // const { numberOfPages, suppliers, categoryFilters } = searchSupplierResponse;
                    // const suppliersArry = []
                    // for (let i = 0; i < suppliers.length; i++) {
                    //     suppliersArry.push(suppliers[i].s)
                    // }
                    searchResults.type = "searchSuccess";
                    searchResults.suppliers = searchSupplierResponse.supplier;
                    // searchResults.pages = numberOfPages;
                    // this.supplierSearchResultsEventEmitter.emit(searchResults);

                    // if (trigger !== "categoryTrigger") {
                    //     this.categoryFilters = categoryFilters;
                    // }
                    this.supplierSearchResultsEventEmitter.emit(searchResults);
                }
                // this.handleError();
                // this.suppliers = [];
                // searchResults.type = "searchFailed";
                // this.supplierSearchResultsEventEmitter.emit(searchResults);
            }
        }else{
            this.loading = false;
        }
    }

    /**
     * Description: generates a query string based on #searchedCriteria and #page number.
     * String will be used in API search request.
     * @Output query string
     */
    private generateQueryString(): string {
        let queryString: string = "?";
        let value: string;
        const keys = Object.keys(this.searchedCriteria);
        for (const key of keys) {
            value = this.searchedCriteria[key];
            if (this.searchedCriteria[key] !== "") {
                queryString += `${key}=${value}&`;
            }
        }
        return `${queryString}page=${this.page}`;
    }

    /**
     * Description: Initialises button props.
     */
    setButtonProps(): void {
        this.advSearchSearchButton = {
            label: "Search",
            color: "white",
            fontSize: 16,
            backgroundColor: "purple",
            borderColor: "lightPurple",
            hoverColor: "purple",
            hoverBackgroundColor: "lightPurple",
            hoverBorderColor: "lightPurple",
            height: 40,
            width: 120,
            padding: "10px",
            icon: "assets/icons/icon-search.svg",
            clickIcon: "assets/icons/icon-search.svg",
            iconPosition: "right",
            iconAlt: "icon-search",
            onClick: () => this.search(SearchTriggerEnum.QuickSearchTrigger)
        };
        this.advSearchCancelButton = {
            label: "Cancel",
            color: "purple",
            fontSize: 16,
            backgroundColor: "white",
            borderColor: "lightPurple",
            hoverColor: "purple",
            hoverBackgroundColor: "white",
            hoverBorderColor: "purple",
            height: 42,
            width: 180,
            padding: "10px",
            icon: "",
            iconPosition: "left",
            onClick: () => {
                this.isAdvanceSearchOpen = !this.isAdvanceSearchOpen;
                if (!this.isAdvanceSearchOpen) {
                    this.searchCriteria.countryLocationTwo = []
                    this.searchCriteria.saStatus = []
                    this.searchCriteria.statusTwo = []
                    this.searchCriteria.countryServedTwo = []
                    this.searchCriteria.companyCode = []
                    this.searchCriteria.duns = ""
                    this.searchCriteria.International = ""
                    this.searchCriteria.tsmId = ""
                    this.searchCriteria.category = []
                    this.searchCriteria.taxId = ""
                    this.searchCriteria.sapId = ""
                    this.searchCriteria.migratedTag = ""
                    this.searchCriteria.biBda = ""
                    this.searchCriteria.domainName = ""
                    this.searchCriteria.onboardingStatus = []
                    this.searchCriteria.requestorEmail = ""
                    this.checkNumber = false
                    this.checkTaxId = false
                    this.checkSapId = false
                    this.checkRequestorEmail = false
                    this.isDisabled = true
                    let elements = <HTMLElement>document.getElementsByTagName("tsm-add-new-supplier")[0].firstChild;
                    setTimeout(() => {
                        let scrollBottom = this.scollPostion().height - window.innerHeight - this.scollPostion().top;
                        if (this.scollPostion().height < 870 && this.scollPostion().height > 700 && this.scollPostion().top > 144 || scrollBottom < 1) {
                            elements.style.bottom = "103px";
                        } else {
                            elements.style.bottom = "31px";
                        }
                    }, 1);
                    console.log(this.searchCriteria);
                }
            }
        };
        this.inviteSupplierButton = {
            label: "Add New Supplier",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "white",
            height: 42,
            width: 240,
            padding: "10px",
            icon: "assets/icons/person_add-white.svg",
            clickIcon: "assets/icons/person_add-white.svg",
            iconPosition: "left",
            iconAlt: "icon-add-new-supplier",
            onClick: () => this.addNewSupplierEmitter.emit()
        };
    }

    /**
     * Description: resets page number to 1. Invoked each time the user triggers a new search.
     */
    resetPageNumber = (): void => {
        this.page = 1;
    };

    /**
     * Description: updates the errorstate of the functiion to true when called
     */
    handleError() {
        this.errorState = true;
    }

    /**
     * Description: updates the errorstate of the functiion to false when called
     */
    clearError() {
        this.errorState = false;
    }
    async saveSectionEvent(e, dropdown) {
        this.searchCriteria[dropdown] = e;
        // this.checkSupplierNameLength();
        this.checkSingleSearchLength();
        console.log(this.searchCriteria);        
        if (dropdown === "countryServedTwo") {
            if (e.length === 0) {
                this.searchCriteria.companyCode = [];
                this.tempcompanyCodeData = [];
                this.isDisabled = true;
            }
            else {
                this.isDisabled = false;
                this.tempcompanyCodeData = [];
                const companycodeurl = `${environment.tsmBackendServiceURL}/countries/getCompanyCodes`;
                const companyCodeData: any = await this.HttpService.PostPromise(companycodeurl, {
                    "countryserved": e
                });
                for (let val of companyCodeData) {
                    for (let key in val) {
                        const data = val[key];
                        for (let code of data) {
                            const obj = {
                                Code: code,
                                Text: code
                            }
                            this.tempcompanyCodeData.push(obj);
                        }
                    }
                }
            }
            const searchCompanyCode = this.tempcompanyCodeData.map((ent: any) => {
                return ent.Code;
            });
            if (this.searchCriteria.companyCode) {
                this.searchCriteria.companyCode = this.searchCriteria.companyCode.filter(item => {
                    return searchCompanyCode.includes(item);
                })
            }
            this.tempcompanyCodeData.sort(function (obj1, obj2) {
                var val1 = obj1.Text;
                var val2 = obj2.Text;
                if (val1 > val2) {
                    return 1;
                } else if (val1 < val2) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
    }

    scollPostion() {
        let t, l, w, h;
        if (document.documentElement && document.documentElement.scrollTop) {
            t = document.documentElement.scrollTop;
            l = document.documentElement.scrollLeft;
            w = document.documentElement.scrollWidth;
            h = document.documentElement.scrollHeight;
        } else if (document.body) {
            t = document.body.scrollTop;
            l = document.body.scrollLeft;
            w = document.body.scrollWidth;
            h = document.body.scrollHeight;
        }
        return {
            top: t,
            left: l,
            width: w,
            height: h
        };
    }

    openPossibleScenariosPopUp() {
        const confirmType = this.possibleScenariosConfig;
        const modalReference = this.ngbModalService.open(PopUpPossibleScenariosComponent, confirmType);
        modalReference.componentInstance.confirmType = confirmType;
        let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.setAttribute("aria-hidden", "false");
        }
    }
}
