import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ActivitylogService {
  activityLogUrl="";
  constructor(private httpService:HttpClient) {
    this.activityLogUrl = `${environment.tsmBackendServiceURL}/activity-log`;
   }


   getAllTask(supplierTsmId){
    const getTaskUrl=`${this.activityLogUrl}/tasks/${supplierTsmId}`
    return this.httpService.get(getTaskUrl)  
   }

   getAllParentChildHistory(supplierTsmId){
    const getTaskUrl=`${this.activityLogUrl}/parentChildHistory/${supplierTsmId}`
    return this.httpService.get(getTaskUrl)  
   }

   getAllSubsupplierHistory(supplierTsmId){
    const getTaskUrl=`${this.activityLogUrl}/subsupplierHistory/${supplierTsmId}`
    return this.httpService.get(getTaskUrl)  
   }

   getUserOperationLogs(supplierTsmId){
    const getTaskUrl=`${this.activityLogUrl}/operationLogs/${supplierTsmId}`
    return this.httpService.get(getTaskUrl)  
   }

}
