<tsm-secondary-banner></tsm-secondary-banner>
<sh-alert *ngIf="showAlert" [props]="alertProps"></sh-alert>
<div class="supplier-information-box-container mt-5">
  <sh-supplier-information-box
    id="supplier-information-box"
    [supplier]="supplier"
    [parentSupplier]="parentSupplier"
    [userType]="userType"
    [esgStatus]="esgStatus"
    [defaultCountryServed]="defaultCountryServed"
    editBtnAuthId="editSupplierProfile=sph"
    changeLocAuthId="changeLocation=tsm"
    enableIndirectTag="true"
    (outerEvent)="openProfilePopup()"
    (terminateEvent)="openTerminatePopup($event)"
  ></sh-supplier-information-box>
</div>
<div class="mx-5 my-5 pt-2">
  <tsm-tab-bar [tabs]="tabs"></tsm-tab-bar>
  <div *ngFor="let tab of tabs">
    <!-- Actions tab -->
    <div *ngIf="tab.id === 'Actions' && tab.active">
      <div class="actions-tittle">
        What would you like to do with this supplier?
      </div>
      <tsm-authority-onboarding
            [supplier]="supplier"
            [allMu]="allMu"
            [allCountry]="allCountry"
            (refershTerminateCallBack)="refershTerminate($event)"
      ></tsm-authority-onboarding>
      <tsm-authority-esg-assessment
            [supplier]="supplier"
            [allMu]="allMu"
            [allCountry]="allCountry"
            [isScope3KeySup]="isScope3KeySup"
            (withdrawSuccessAlert)="setAlertProps($event)"
            (refershTerminateCallBack)="refershTerminate($event)"
            style="width: 100%"
      ></tsm-authority-esg-assessment>
    </div>
    
    <!-- Relationships tab -->
    <div *ngIf="tab.id === 'Relationships' && tab.active">
      <tsm-buyer-relationship
        [supplierTsmId]="selectedSupplierTsmId"
        [isSupplierParent]="isSupplierParent"
        [isIndirect]="supplier.indirect"
      ></tsm-buyer-relationship>
    </div>
    <!-- scoring tab -->
    <div *ngIf="tab.id === 'Scoring' && tab.active && isReviewDataAvailabe">
      <tsm-scoring [reviewData]="reviewData"></tsm-scoring>
    </div>
   <!-- suppliers tab -->
    <div *ngIf="tab.id === 'Suppliers' && tab.active">
      <tsm-sub-suppliers [supplierTsmId]="supplierTsmId"></tsm-sub-suppliers>
      <tsm-historical-sub-suppliers [supplierTsmId]="supplierTsmId"></tsm-historical-sub-suppliers>
    </div>

  </div>
</div>
<tsm-pop-up-bv hidden="true"></tsm-pop-up-bv>
