import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AlertPropsType,
  ButtonPropsType,
  ComponentConfig,
  DynamicContext,
  DynamicFormComponent,
  HttpService,
} from "@lsl16/sustainability-shared-components"
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService } from 'src/app/services/EventService.service';
import { countryList } from 'src/utilities/const/country-list';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SupplierHubService } from 'src/app/services/supplierHub/supplier-hub.service';
import { Router, ActivatedRoute } from "@angular/router";
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';

@Component({
  selector: 'tsm-pop-up-risk-assessment',
  templateUrl: './pop-up-risk-assessment.component.html',
  styleUrls: ['./pop-up-risk-assessment.component.sass']
})
export class PopUpRiskAssessmentComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    public ngbModalService: NgbModal,
    public eventService: EventService,
    private supplierHubService: SupplierHubService,
    private router: Router,
    private route: ActivatedRoute,
    private riskAssessmentService: RiskassessmentService
  ) { }

  @ViewChild("form", { static: false }) form: DynamicFormComponent;
  @Input() confirmType;
  alertProps: AlertPropsType;
  showAlert: boolean;
  countryServed: string;
  marketUnit: string;
  supplierTsmId: string;
  categoryCode: string;
  subCategoryCode: string;
  categoryName: string;
  subCategoryName: string;
  independentContractor: string;
  supplierPocFirstName: string;
  supplierPocLastName: string;
  supplierPocEmail: string;
  supplier: any;
  configs: Array<ComponentConfig> = [];
  model: any = {};
  context: DynamicContext;
  independentContractorFlag: boolean = false;

  userType: any;
  showComponent = false;
  values: any = {};

  callingComponent: String = "tsm-pop-up-risk-assessment";

  continueProps: ButtonPropsType = {
    label: "Continue",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "14px 10px",
    margin: "0 0 0 2px",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    onClick: async () => {
      await this.sendRASelections();
    }
  };



  cancelProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  ngOnInit() {
    this.supplier = this.confirmType.supplier;
    this.context = new DynamicContext();
    this.configs = JSON.parse(localStorage.getItem("riskAssessmentConfigs"));
    this.showComponent = true;
    this.userType = this.authService.getRole();
    this.accessbility();
  }

  async sendRASelections() {
    const tempValues = this.form.values();
    if((tempValues.subCategory == 5002 || tempValues.subCategory == 5021) && tempValues.independentContractor == "") {
      this.independentContractorFlag = true;
      return;
    } else{
      this.independentContractorFlag = false;
    }
    if (this.form.validate()) {
      this.values = this.form.values();
      await this.getRaMetaData(this.values);
      this.activeModal.close(); 
      this.router.navigateByUrl(`/risk-assessment-questionnaire/0/0/${this.values.category}/${this.values.subCategory}/${this.values.independentContractor}`);
    }

  };

  async getRaMetaData(values) {
    // Assigning the meta data values seelcted for RA on click of Submit button in triage popup
    const countryList = values.countryServed;
    this.countryServed = countryList.join(", ");
    this.marketUnit = values.marketUnit;
    this.categoryCode = values.category;
    this.subCategoryCode = values.subCategory;
    this.categoryName = (<HTMLInputElement>document.getElementById("category")).value;
    this.subCategoryName = (<HTMLInputElement>document.getElementById("subCategory")).value;
    this.independentContractor = values.independentContractor === '' ? null : values.independentContractor;
    this.supplierPocFirstName = values.firstName;
    this.supplierPocLastName = values.lastName;
    this.supplierPocEmail = values.emailAddress;
    // passing values to risk assessment questionnaire via riskAssessmentService
    this.riskAssessmentService.raMetadata = {
      "categoryCode": this.categoryCode,
      "subCategoryCode": this.subCategoryCode,
      "tsmId": this.confirmType.supplierTsmId,
      "requestorName": this.authService.getLoginUser().email,
      "marketUnit": this.marketUnit,
      "countryServed": this.countryServed,
      "category": this.categoryName,
      "subCategory": this.subCategoryName,
      "supplierPoc": this.supplierPocEmail,
      "supplierName": this.supplier.supplierName,
      "independentContractor": this.independentContractor,
      "supplierContactFirstName": this.supplierPocFirstName,
      "supplierContactLastName": this.supplierPocLastName,
    }
  }

  onAlertDismiss = (): void => {
    this.showAlert = false;
  };

  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  accessbility() {
    const tsmRoot = document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

}
